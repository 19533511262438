.App {
    position: relative;
    background-color: #5c688e;
    z-index: 0;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

/* .App-header {
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
} */



#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.avatar {
    width: 300px;
    clip-path: circle();
}


.about-me {
    padding-top: 100px;
}

.nav-bar {
    background-color: #7c957c;
    /* height: 100px; */
}

.text-color {
    color: white;
}

.nav-bar-elements {
    font-size: x-large;
}

.starter-content {
    padding-top: 150px;
    color: white;
}

.contact-me h1 {
    padding-top: 150px;
    text-align: center;
    color: white;
}

.contact-me {
    padding-bottom: 100px;
}

.main-content {
    padding-top: 125px;
    color: white;
}

.links {
    padding-top: 100px;
}

.my-experience {
    padding-top: 125px;
    color: white;
}

.experience h5 {
    padding-top: 35px;
}